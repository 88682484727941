<template>
	<Template
			v-bind="{
				...params,
				isLoading
			}"
			@onSubmit="() => onLogin()"
			@goTo="(path) => $router.push(path)"
	/>
</template>
<script>
import Template from '../components/templates/Login/Login';
import {c} from '@/components/constants.js'

export default {
	components: {
		Template,
	},
	methods: {
		async onLogin() {
			this.params.inputs.email.error = null
			this.params.inputs.password.error = null
			const forms = this.params.inputs;
			console.log(forms, 'forms ====');
			if (!forms.email?.value) {
				this.params.inputs.email.error = 'Please insert email!'
				return false;
			}
			if (!forms.password?.value) {
				this.params.inputs.password.error = 'Please insert password!';
				return false;
			}
			try {
				this.isLoading = true;
				await this.actions.login({email: forms.email?.value, password: forms?.password?.value});
				// if (this.state.currentUser?.id && !this.state.currentUser?.emails[0].verified) {
				// 	this.$router.push({name: 'Verify Email Step', query: {email: this.state.currentUser?.emails[0]?.address}})
				// 	return false;
				// }
				// if (this.state.currentUser?.id && !this.state.currentUser?.gender) {
				// 	this.$router.push({name: 'Demographic Information'});
				// 	return false;
				// }
				// if (this.state.currentUser?.id && this.state.currentUser?.tags?.filter(t => t?.type === 'INTEREST_ACTIVITY')?.length === 0) {
				// 	this.$router.push({name: 'Interest And Activity'});
				// 	return false;
				// }
				if (this.state.currentUser?.id) {
					this.actions.alert.showSuccess({message: 'Logged In Successfully'});
					this.$router.push('/')
				}
			} catch (e) {
				console.log(e)
			} finally {
				this.isLoading = false;
			}
		}
	},
	data() {
		return {
			params: c.login,
			isLoading: false,
		}
	},
	mounted() {
		this.params.inputs.email.value = null
		this.params.inputs.password.value = null
	}
}
</script>
